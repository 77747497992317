<template>
  <div class="mx-auto max-w-screen-xl" :class="wrapperClass">
    <div class="newsletter-container" :class="containerClass">
      <div class="w-full md:w-5/12 flex flex-col">
        <span class="mb-2 block" :class="titleClass">
          {{ title || newsletterStrapiData?.title }}
        </span>
        <span class="mb-4 block" :class="textClass">
          {{ subtitle || newsletterStrapiData?.subtitle }}
        </span>
        <form
          action="#"
          class="relative flex flex-row min-[400px]:flex-row mt-4 rounded-md justify-between"
          :class="formClass"
          @submit.prevent="handleSubscribe"
        >
          <input
            v-model="form.email"
            class="rounded-md min-h-[40px] w-full pl-2 md:pl-4 pr-28 md:pr-32 grow border border-gray-300 focus:border-primary-light focus-visible:outline-none"
            :class="
              invalidKeys?.includes('email')
                ? 'border-danger-active focus:border-danger-active'
                : ''
            "
            type="email"
            :placeholder="newsletterStrapiData?.placeholder"
            @input="$emit('clearErrors', 'email')"
          />
          <Button type="submit" class="absolute right-0">
            {{ newsletterStrapiData?.buttonLabel }}
          </Button>
          <span
            v-if="invalidKeys?.includes('email')"
            class="text-red text-sm absolute top-20 min-[400px]:top-12"
          >
            {{ errors?.email }}
          </span>
        </form>
        <span v-show="isEmailSubmitted" class="mt-2 text-primary text-sm">
          {{ $t('general.newsletter.successMessage') }}
        </span>
      </div>
      <div class="hidden md:flex gap-4">
        <div
          v-for="(media, index) in imagesList"
          :key="index"
          class="image-container"
        >
          <NuxtLink :to="media?.link" class="w-full h-full block">
            <NuxtImg
              v-if="strapiImageGetters?.getImageUrl(media?.image?.data)"
              :src="String(strapiImageGetters?.getImageUrl(media?.image?.data))"
              class="h-full object-cover rounded-xl"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { toast, type ToastOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { useFormValidation } from '~/composables/useFormValidation'

const props = withDefaults(
  defineProps<{
    title?: string
    subtitle?: string
    wrapperClass?: string
    containerClass?: string
    titleClass?: string
    textClass?: string
    formClass?: string
    images?: Array[]
  }>(),
  {
    title: '',
    subtitle: '',
    wrapperClass: 'p-4 -mt-32',
    containerClass: 'translate-y-1/2 p-4 md:p-8 items-center',
    titleClass: 'text-[1.8rem] font-bold leading-snug',
    textClass: 'font-medium',
    formClass: 'md:my-4',
    images: () => [],
  }
)

const { locale } = useI18n()
const { data: newsletterStrapiResponse } = await useStrapiSingleType(
  `newsletter?locale=${locale.value}&populate=deep,4`
)
const newsletterStrapiData = computed(
  () => newsletterStrapiResponse.value?.data?.attributes
)
const form = ref<{ email: string }>({ email: '' })
const rules = ref({ email: 'required|email' })
const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
  form,
  rules
)
const isEmailSubmitted = ref('')
const handleSubscribe = async () => {
  await validate()

  if (errors.value !== null && Object.keys(errors.value)?.length > 0) {
    return
  }
  try {
    await kftApiRequest(`/v1/store/customers/subscribe-newsletter`, {
      method: 'POST',
      body: { email: form.value.email },
    })

    isEmailSubmitted.value = true
    sendNewsletterSubscription()
  } catch (err) {
    toast(e.data?.message || t('general.error'), {
      autoClose: 2000,
      type: 'error',
      position: toast.POSITION.TOP_CENTER,
      multiple: false,
    } as ToastOptions)
  }
}

const imagesList = computed(() => {
  if (props.images?.length) return props.images

  return newsletterStrapiData.value?.media
})
</script>
<style scoped lang="postcss">
.newsletter-container {
  @apply flex gap-8 justify-between;
  @apply bg-white border border-gray-300;
  @apply rounded-xl;
}

.image-container {
  @apply hidden md:block;
  @apply w-52 h-48;
  @apply rounded-xl;
  @apply shadow-lime-500;
  box-shadow: -5px 5px 0 0 #eaff90;

  &:nth-child(2) {
    @apply hidden lg:block;
  }

  &:nth-child(3) {
    @apply hidden xl:block;
  }
}
</style>
